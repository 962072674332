import { useRef, createContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import crypto from "crypto-js";

import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  TwitterIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
} from "react-share";
import { MDXProvider } from "@mdx-js/react";
import { Helmet } from "react-helmet-async";
import { useGeneralBrain } from "./GeneralBrain";

export const BlogYear = () => {
  const currentYear = new Date().getFullYear();
  return <div>{currentYear}</div>;
};

export const NewsLetter = () => {
  return <div>Get Premium | Updates | Guides</div>;
};

export const SearchIcon = ({ size = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    stroke="var(--themeColor)"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="7" cy="7" r="6"></circle>
    <line x1="18" y1="18" x2="13" y2="13"></line>
  </svg>
);

export const MoonIcon = ({ size = 12, color = "var(--bg)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
  </svg>
);

export const SunIcon = ({ size = 12, color = "var(--bg)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="5" />
    <path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
  </svg>
);

export const CaretUp = ({ size = 18, color = "var(--bg)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M18 15l-6-6-6 6" />
  </svg>
);

export const UserIcon = ({ size = 14, color = "var(--softTextColor)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512 "
    width={size}
    height={size}
    fill={color}
  >
    <path
      d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7
       48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4
        0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
    />
  </svg>
);

export const CalenderIcon = ({ size = 14, color = "var(--softTextColor)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width={size}
    height={size}
    fill={color}
  >
    <path
      d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 
      32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 
      64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48
       48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0
        16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0
         8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 
         7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 
         16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2
         16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0
          8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7
          .2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"
    />
  </svg>
);

export const CategoryIcon = ({ size = 14, color = "var(--softTextColor)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width={size}
    height={size}
    fill={color}
  >
    <path
      d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2
  15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 
  16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 
  39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8
   80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8
    0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"
    />
  </svg>
);

export const NextDoubleIcon = ({ size = 12, color = "var(--textColor)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={size}
    height={size}
    fill={color}
  >
    <path
      d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 
    0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8
     12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 
     0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7
      256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z"
    />
  </svg>
);
export const NextDoubleIconx = ({ size = 10, color = "var(--textColor)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={size}
    height={size}
    fill={color}
  >
    <path
      d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 
    0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8
     12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 
     0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7
      256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z"
    />
  </svg>
);
export const PrevDoubleIcon = ({ size = 10, color = "var(--textColor)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={size}
    height={size}
    fill={color}
  >
    <path
      d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5
     12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5
      12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160zm352-160l-160 160c-12.5 12.5-12.5
       32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L301.3 256 438.6 
       118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z"
    />
  </svg>
);

const CardList = ({ post }) => {
  const { url, title, author, date, category } = post;
  const words = title.split(" ");
  const chunks = [];

  let currentChunk = "";
  words.forEach((word) => {
    if ((currentChunk + " " + word).length <= 26) {
      currentChunk = currentChunk ? currentChunk + " " + word : word;
    } else {
      chunks.push(currentChunk);
      currentChunk = word;
    }
  });

  if (currentChunk) {
    chunks.push(currentChunk);
  }

  return (
    <a href={url} className="card-list-container">
      <div className="card-list-container-img">
        {chunks.map((chunk, index) => (
          <div key={index} className="title-chunk">
            {chunk}
          </div>
        ))}
      </div>
      <div className="card-list-container-title-desc">
        <div className="card-list-container-title darts ">{title}</div>
        <div className="card-list-container-desc">
          <div className="card-list-container-icon-blogname">
            <span className="card-list-container-icon">
              <UserIcon />
            </span>
            {author}
          </div>
          <div className="card-list-container-icon-date">
            <span className="card-list-container-icon">
              <CalenderIcon />
            </span>
            {date}
          </div>
          <div className="card-list-container-icon-date">
            <span className="card-list-container-icon">
              <CategoryIcon />
            </span>
            {category}
          </div>
        </div>
      </div>
    </a>
  );
};

const Categorylist = ({ category }) => {
  return (
    <a href={`/category/${category}`} className="link-list-container">
      <div className="link-list-container-title darts">{category}</div>
    </a>
  );
};

const LinkList = ({ post }) => {
  const { url, title } = post;
  return (
    <a href={url} className="link-list-container">
      <div className="link-list-container-title darts ">{title}</div>
    </a>
  );
};

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination-container">
      <div
        className={`pagination-button ${
          currentPage === 1 ? "pagination-disabled" : ""
        }`}
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <span className="pagination-icon">
          <PrevDoubleIcon />
        </span>
        Previous
      </div>
      {pageNumbers.map((number) => (
        <div
          key={number}
          className={`pagination-button ${
            currentPage === number ? "pagination-active" : ""
          }`}
          onClick={() => paginate(number)}
        >
          {number}
        </div>
      ))}
      <div
        className={`pagination-button ${
          currentPage === Math.ceil(totalPosts / postsPerPage)
            ? "pagination-disabled"
            : ""
        }`}
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
      >
        Next{" "}
        <span className="pagination-icon">
          <NextDoubleIconx />
        </span>
      </div>
    </div>
  );
};

const ScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div onClick={scrollToTop} className="scrolltotop-container">
      <CaretUp />
    </div>
  );
};

const shareUrl = window.location.href;

const Share = () => {
  return (
    <div className="share-container">
      <h2 className="theme">Share Post</h2>
      <div className="share-icon-container">
        <div className="share-icon">
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
        </div>
        <div className="share-icon">
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
        </div>
        <div className="share-icon">
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={40} round={true} />
          </LinkedinShareButton>
        </div>
        <div className="share-icon">
          <TwitterShareButton url={shareUrl}>
            <TwitterIcon size={40} round={true} />
          </TwitterShareButton>
        </div>
        <div className="share-icon">
          <PinterestShareButton url={shareUrl}>
            <PinterestIcon size={40} round={true} />
          </PinterestShareButton>
        </div>
        <div className="share-icon">
          <RedditShareButton url={shareUrl}>
            <RedditIcon size={40} round={true} />
          </RedditShareButton>
        </div>
        <div className="share-icon">
          <TelegramShareButton url={shareUrl}>
            <TelegramIcon size={40} round={true} />
          </TelegramShareButton>
        </div>
      </div>
    </div>
  );
};

const SubNavHome = () => {
  return (
    <div className="subnav-container">
      <a href="/" className="subnav-home dart">
        Home
      </a>
      <span className="subnav-icon">
        <NextDoubleIcon />
      </span>
    </div>
  );
};

const Footer = () => {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const { posts, BlogName } = useGeneralBrain();

  const categoriesArray = posts
    ? Array.from(new Set(posts.map((post) => post.category)))
    : [];

  const validateEmail = (email) => {
    // Simple email validation
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    setSubmitting(true);

    // Simulate submission delay
    setTimeout(() => {
      setSubmitting(false);
      setSubmitted(true);
      setEmail("");
    }, 2000); // Adjust the timeout as needed
  };

  return (
    <div className="footer-container">
      <div className="footer-info">
        <a href="/" className="footer-logo-img">
          <img src="/logo.png" alt="logo" className="footer-logo" />
          <h1 className="footer-logoText">{BlogName}</h1>
        </a>
        <div className="footer-newslettertitle">
          <NewsLetter />
        </div>
        <p className="footer-desc">
          Subscribe to learn about new technology and updates. Join over 5000+
          members community to stay up to date with the latest news.
        </p>
        <form className="footer-newsletter" onSubmit={handleSubmit}>
          <input
            type="email"
            className="footer-inputbox"
            placeholder="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="footer-button" disabled={submitting}>
            {submitting ? "Wait..." : "SUBMIT"}
          </button>
        </form>
        {error && <p className="footer-error">{error}</p>}
        {submitted && <p className="footer-success">Submitted successfully!</p>}
        <div className="footer-parent">
          <span className="footer-copyright">
            ©<BlogYear /> {" - "}
            {BlogName}. All rights reserved.{" "}
          </span>
          <div className="footer-xmlup">
            <a href="/sitemap" className="footer-xml">
              Sitemap.xml
            </a>
            <ScrollToTop />
          </div>
        </div>
      </div>
      <div className="footer-links">
        <div className="footer-list">
          <span className="footer-listTitle">Links</span>
          <a href="/" className="dart">
            Home
          </a>
          <a href="/blog" className="dart">
            Blog
          </a>
          <a href="/about" className="dart">
            About
          </a>
          <a href="/contact" className="dart">
            Contact
          </a>
          <a href="/advertise" className="dart">
            Advertise
          </a>
          <a href="/affiliate" className="dart">
            Affiliate
          </a>
          <a href="/privacy" className="dart">
            Privacy
          </a>
          <a href="/terms" className="dart">
            Terms
          </a>
          <a href="/disclaimer" className="dart">
            Disclaimer
          </a>
        </div>
        <div className="footer-list">
          <span className="footer-listTitle">Categories</span>
          {categoriesArray.map((category, index) => (
            <a
              key={index}
              href={`/category/${category}`}
              className={`dart category`}
            >
              {category}
            </a>
          ))}
        </div>
        <div className="footer-list">
          <span className="footer-listTitle">Social</span>
          <a href="/" className="dart">
            Facebook
          </a>
          <a href="/" className="dart">
            Instagram
          </a>
          <a href="/" className="dart">
            Tiktok
          </a>
          <a href="/" className="dart">
            Youtube
          </a>
        </div>
      </div>
      <div className="footer-parentx">
        <span className="footer-copyrightx">
          ©<BlogYear /> {" - "}
          {BlogName}. All rights reserved.{" "}
        </span>
        <a href="/sitemap.xml " className="footer-xmlx dart">
          sitemap.xml
        </a>
        <ScrollToTop />
      </div>
    </div>
  );
};

export const Navbar = ({ theme, setTheme }) => {
  const { BlogName } = useGeneralBrain();

  const [open, setOpen] = useState(false);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const openNav = () => {
    setOpen(true);
  };

  const closeNav = () => {
    setOpen(false);
  };

  return (
    <div className="navbar-container">
      <a href="/" className="navbar-logo-name">
        <div className="navbar-logo-img">
          <img src="/logo.png" alt="logo" className="navbar-logo" />
        </div>
        <div className="navbar-blogname">{BlogName}</div>
      </a>
      <div className="navbar-links">
        <div className="themetoggle-container" onClick={toggleTheme}>
          <div>{theme === "light" ? <MoonIcon /> : <SunIcon />}</div>
        </div>
        <a href="/" className="navbar-link dart">
          Home
        </a>
        <a href="/blog" className="navbar-link dart">
          Blog
        </a>
        <a href="/category" className=" navbar-link dart">
          Category
        </a>
        <a href="/about" className="navbar-link dart">
          About
        </a>
        <a href="/contact" className="navbar-link dart">
          Contact
        </a>
        <a href="/search" className="navbar-link">
          <SearchBar />
        </a>
        <div className="navbar-authlink">
          <div className="navbar-burger" onClick={openNav}>
            <div className="navbar-line"></div>
            <div className="navbar-line"></div>
            <div className="navbar-line"></div>
          </div>

          <div className={`navbar-sidenav ${open ? "navbar-open" : ""}`}>
            <div className="navbar-close" onClick={closeNav}>
              <span className="navbar-closechild">&times;</span>
            </div>
            <a href="/" className=" navbar-sidelink dart">
              Home
            </a>
            <a href="/blog" className=" navbar-sidelink dart">
              Blog
            </a>
            <a href="/category" className=" navbar-sidelink dart">
              Category
            </a>
            <a href="/about" className=" navbar-sidelink dart">
              About
            </a>
            <a href="/contact" className=" navbar-sidelink dart">
              Contact
            </a>

            <a href="/search" className=" navbar-sidelink">
              <SearchBar />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BlogList = () => {
  const { posts } = useGeneralBrain();
  const postsPerPage = 12; // Number of posts to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index of the first and last post to display on the current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Function to handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className="most-recent-container-cardlists">
        {currentPosts.map((post) => (
          <CardList key={post.id} post={post} />
        ))}
      </div>
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export const CategoryLists = () => {
  const { posts } = useGeneralBrain();

  const uniqueCategories = Array.from(
    new Set(posts.map((post) => post.category))
  );

  return (
    <div>
      <h2 className="most-recent-container-title theme">Category Lists</h2>
      <div>
        {uniqueCategories.map((category, index) => (
          <Categorylist key={index} category={category} />
        ))}
      </div>
    </div>
  );
};

export const CookieConsent = () => {
  const { DirectLink } = useGeneralBrain();
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    window.open(DirectLink, "_blank");
    setAccepted(true);
    localStorage.setItem("cookieConsent", "accepted");
  };

  const isAccepted = localStorage.getItem("cookieConsent") === "accepted";

  if (isAccepted || accepted) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <p>
        This website uses cookies to ensure you get the best experience.{" "}
        <a href="/privacy" className="dart cookie-privacy">
          Privacy
        </a>
      </p>
      <button onClick={handleAccept}>Accept</button>
    </div>
  );
};

export const Featured = () => {
  const { FeaturedText, BlogName } = useGeneralBrain();
  return (
    <section className="featured-section">
      <div className="featured-overlay"> </div>
      <div className="featured-content">
        <div>
          <h1 className="featured-title">Welcome to {BlogName}</h1>
          <p className="featured-description">{FeaturedText}</p>
          <a href="/about">
            <button className="featured-button">Learn More</button>
          </a>
        </div>
      </div>
    </section>
  );
};
export const MostRecent = () => {
  const { posts } = useGeneralBrain();

  const MostRecentPost = posts.slice(0, 3);

  return (
    <div className="most-recent-container">
      <h2 className="most-recent-container-title">Most Recent</h2>
      <div className="most-recent-container-cardlists">
        {MostRecentPost.map((post) => (
          <CardList key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
};

export const PeopleChoice = () => {
  const { posts } = useGeneralBrain();

  const PeopleChoicePost = posts.slice(7, 10);

  return (
    <div className="most-recent-container">
      <h2 className="most-recent-container-title">People Choice</h2>
      <div className="most-recent-container-cardlists">
        {PeopleChoicePost.map((post) => (
          <CardList key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
};

export const PopularCategory = () => {
  const { posts } = useGeneralBrain();

  const uniqueCategories = posts
    ? Array.from(new Set(posts.map((post) => post.category)))
    : [];

  return (
    <div className="popular-category">
      <h2 className="popular-category-title">Popular Category</h2>
      <div className="popular-categories">
        {uniqueCategories.map((category, index) => (
          <a
            key={index}
            href={`/category/${category}`}
            className={`popular-category-list category-${index + 10}`}
          >
            {category}
          </a>
        ))}
      </div>
    </div>
  );
};

export const RecentPosts = () => {
  const { posts } = useGeneralBrain();

  const BlogPost = posts ? posts.slice(0, 10) : [];
  return (
    <div>
      <h2 className="most-recent-container-title theme">Recent Post</h2>
      <div>
        {BlogPost.map((post) => (
          <LinkList key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
};

export const PopularPost = () => {
  const { posts } = useGeneralBrain();
  const MostRecentPost = posts.slice(3, 6);
  return (
    <div className="most-recent-container">
      <h2 className="most-recent-container-title">Popular Post</h2>
      <div className="most-recent-container-cardlists">
        {MostRecentPost.map((post) => (
          <CardList key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
};

const SearchBar = () => {
  return (
    <div>
      <SearchIcon />
    </div>
  );
};

const SearchEngine = () => {
  const { posts } = useGeneralBrain();
  const [searchTerm, setSearchTerm] = useState("");

  // Filter posts based on search term
  const filteredPosts = posts.filter((post) => {
    // Convert both title and search term to lowercase for case-insensitive search
    const lowerCaseTitle = post.title.toLowerCase();
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    // Check if the title includes the search term
    return lowerCaseTitle.includes(lowerCaseSearchTerm);
  });

  return (
    <div>
      <h2 className="most-recent-container-title theme">Search Engine</h2>
      <div>
        <div className="search-input-container">
          <input
            type="text"
            placeholder="Search an article..."
            className="search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span className="search-btn">Search</span>
        </div>

        {searchTerm && filteredPosts.length > 0 && (
          <div>
            {filteredPosts.map((post) => (
              <LinkList key={post.id} post={post} />
            ))}
          </div>
        )}
        {searchTerm && filteredPosts.length === 0 && (
          <div className="search-not-found">No matching posts found</div>
        )}
      </div>
    </div>
  );
};

const TableOfContents = () => {
  const [headings, setHeadings] = useState([]);
  const [isOpen, setIsOpen] = useState(true); // State variable to track whether the table of contents is open

  useEffect(() => {
    const generateTableOfContents = () => {
      const headingsArray = Array.from(
        document.querySelectorAll("h2, h3, h4")
      ).map((heading) => {
        const level = parseInt(heading.tagName[1]); // Extract heading level (e.g., h2, h3, h4)
        const text = heading.textContent; // Get heading text
        const id = heading.id; // Get heading ID
        return { level, id, text };
      });

      // Construct hierarchical structure
      const toc = constructHierarchy(headingsArray);
      setHeadings(toc);
    };

    generateTableOfContents();

    return () => {
      // Clean up function (if needed)
    };
  }, []);

  // Function to construct hierarchical structure
  const constructHierarchy = (headingsArray) => {
    const toc = [];
    let h2Count = 0;
    let h3Count = 0;
    let h4Count = 0;

    headingsArray.forEach((heading) => {
      if (heading.level === 2) {
        h2Count++;
        h3Count = 0; // Reset h3 count when encountering a new h2
        h4Count = 0; // Reset h4 count when encountering a new h2
        toc.push({ ...heading, number: h2Count, children: [] }); // Initialize children array
      } else if (heading.level === 3) {
        h3Count++;
        h4Count = 0; // Reset h4 count when encountering a new h3
        toc[toc.length - 1].children.push({
          ...heading,
          number: `${h2Count}.${h3Count}`,
          children: [], // Initialize children array
        });
      } else if (heading.level === 4) {
        h4Count++;
        toc[toc.length - 1].children[
          toc[toc.length - 1].children.length - 1
        ].children.push({
          ...heading,
          number: `${h2Count}.${h3Count}.${h4Count}`,
        });
      }
    });

    return toc;
  };

  // Handle click on the button to toggle the visibility of the table of contents
  const toggleTableOfContents = () => {
    setIsOpen(!isOpen);
  };

  // Recursive function to render the table of contents
  const renderTOC = (tocItems) => {
    return (
      <ul>
        {tocItems.map((item, index) => (
          <li key={index} className={`heading-${item.level}`}>
            <span className="toc-text">{item.text}</span>
            {item.children && renderTOC(item.children)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="table-of-contents">
      <div className="toc-flex">
        <span className="toc-title">Table of Contents</span>{" "}
        <button className="toc-button" onClick={toggleTableOfContents}>
          {isOpen ? "Close" : "Open"}
        </button>
      </div>
      {isOpen && renderTOC(headings)}
    </div>
  );
};

export const ThemeContext = createContext();

const Home = () => {
  return (
    <div className="">
      <Featured />
      <PopularCategory />
      <MostRecent />
      <PopularPost />
      <PeopleChoice />
    </div>
  );
};

const Category = () => {
  const { posts } = useGeneralBrain();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { category } = useParams();
  const postsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setSelectedCategory(category);
  }, [category]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const filteredPosts = selectedCategory
    ? posts.filter((post) => post.category === selectedCategory)
    : posts;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleResetCategory = () => {
    setSelectedCategory(null);
  };

  useEffect(() => {
    if (category) {
      document.title = category;
      window.scrollTo(0, 0);
    }
  }, [category]);

  const uniqueCategories = [...new Set(posts.map((post) => post.category))];

  return (
    <>
      <div className="category-list">
        <button
          className={`category-list-button ${
            selectedCategory === null ? "category-list-button-active" : ""
          }`}
          onClick={handleResetCategory}
        >
          # All
        </button>
        {uniqueCategories.map((categoryItem, index) => (
          <a
            key={index}
            href={`/category/${categoryItem}`} // Set href dynamically
            className={`category-list-button ${
              selectedCategory === categoryItem
                ? "category-list-button-active"
                : ""
            }`}
            onClick={() => handleCategorySelect(categoryItem)}
          >
            # {categoryItem}
          </a>
        ))}
      </div>

      <div className="most-recent-container-cardlists">
        {currentPosts.map((post) => (
          <CardList key={post.id} post={post} />
        ))}
      </div>

      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={filteredPosts.length} // Use the length of filteredPosts instead of all posts
        paginate={paginate}
        currentPage={currentPage}
      />
    </>
  );
};

const Blog = () => {
  return (
    <div>
      <BlogList />
    </div>
  );
};

export function Banner300x250x1() {
  const { Bannar300x250 } = useGeneralBrain();
  const banner = useRef();
  const atOptions = {
    key: Bannar300x250,
    format: "iframe",
    height: 250,
    width: 300,
    params: {},
  };

  useEffect(() => {
    if (banner.current && !banner.current.firstChild) {
      const conf = document.createElement("script");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

      banner.current.append(conf);
      banner.current.append(script);
    }

    // eslint-disable-next-line
  }, [banner]);

  return (
    <div
      className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={banner}
    ></div>
  );
}

export function Banner160x600x1() {
  const { Bannar160x600 } = useGeneralBrain();
  const banner = useRef();
  const atOptions = {
    key: Bannar160x600,
    format: "iframe",
    height: 600,
    width: 160,
    params: {},
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (banner.current && !banner.current.firstChild) {
        const conf = document.createElement("script");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

        banner.current.append(conf);
        banner.current.append(script);
      }
    }, 2000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [banner]);

  return (
    <div
      className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={banner}
    ></div>
  );
}

export function Banner728x90x1() {
  const { Bannar728x90 } = useGeneralBrain();
  const banner = useRef();
  const atOptions = {
    key: Bannar728x90,
    format: "iframe",
    height: 90,
    width: 728,
    params: {},
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (banner.current && !banner.current.firstChild) {
        const conf = document.createElement("script");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

        banner.current.append(conf);
        banner.current.append(script);
      }
    }, 1000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [banner]);

  return (
    <div
      className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={banner}
    ></div>
  );
}

export function Banner468x60x1() {
  const { Bannar468x60 } = useGeneralBrain();
  const banner = useRef();
  const atOptions = {
    key: Bannar468x60,
    format: "iframe",
    height: 60,
    width: 468,
    params: {},
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (banner.current && !banner.current.firstChild) {
        const conf = document.createElement("script");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

        banner.current.append(conf);
        banner.current.append(script);
      }
    }, 6000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [banner]);

  return (
    <div
      className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={banner}
    ></div>
  );
}

export function Banner320x50x1() {
  const { Bannar320x50 } = useGeneralBrain();
  const banner = useRef();
  const atOptions = {
    key: Bannar320x50,
    format: "iframe",
    height: 50,
    width: 320,
    params: {},
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (banner.current && !banner.current.firstChild) {
        const conf = document.createElement("script");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

        banner.current.append(conf);
        banner.current.append(script);
      }
    }, 8000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [banner]);

  return (
    <div
      className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={banner}
    ></div>
  );
}

export function Banner160x300x1() {
  const { Bannar160x300 } = useGeneralBrain();
  const banner = useRef();
  const atOptions = {
    key: Bannar160x300,
    format: "iframe",
    height: 300,
    width: 160,
    params: {},
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (banner.current && !banner.current.firstChild) {
        const conf = document.createElement("script");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

        banner.current.append(conf);
        banner.current.append(script);
      }
    }, 10000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [banner]);

  return (
    <div
      className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={banner}
    ></div>
  );
}

export function Banner300x250x2() {
  const { Bannar300x250 } = useGeneralBrain();
  const banner = useRef();
  const atOptions = {
    key: Bannar300x250,
    format: "iframe",
    height: 250,
    width: 300,
    params: {},
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (banner.current && !banner.current.firstChild) {
        const conf = document.createElement("script");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

        banner.current.append(conf);
        banner.current.append(script);
      }
    }, 12000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [banner]);

  return (
    <div
      className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={banner}
    ></div>
  );
}

export function Banner728x90x2() {
  const { Bannar728x90 } = useGeneralBrain();
  const banner = useRef();
  const atOptions = {
    key: Bannar728x90,
    format: "iframe",
    height: 90,
    width: 728,
    params: {},
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (banner.current && !banner.current.firstChild) {
        const conf = document.createElement("script");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

        banner.current.append(conf);
        banner.current.append(script);
      }
    }, 14000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [banner]);

  return (
    <div
      className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={banner}
    ></div>
  );
}

export function SocialBarx1() {
  const { SocialBar } = useGeneralBrain();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = SocialBar;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [SocialBar]);

  return <div></div>;
}

export function PopUnderx1() {
  const { PopUnder } = useGeneralBrain();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = PopUnder;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [PopUnder]);

  return <div></div>;
}

const NextPrev = ({ nextUrl, prevUrl,  }) => {
  const { blogId,  ipAddress} = useGeneralBrain();

  const hasPrevPost = prevUrl !== null;
  const hasNextPost = nextUrl !== null;

  const [disabled, setDisabled] = useState(true);
  const [count, setCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [errorCode, setErrorCode] = useState(""); 
  const totalPages = 6;

  const [code, setCode] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisabled(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let timer;
    if (disabled) {
      timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [disabled]);

  useEffect(() => {
    const storedPageHash = localStorage.getItem("currentPageHash");
    const isCompleted = localStorage.getItem("c27yxbi09r0jmfemvpjopsov");
    let pageMatched = false;

    for (let i = 1; i <= totalPages; i++) {
      const pageIdentifier = hashPageIdentifier(blogId, i);
      if (pageIdentifier === storedPageHash) {
        setCurrentPage(i);
        pageMatched = true;
        break;
      }
    }

    if (!storedPageHash) {
      setCurrentPage(0);
    } else if (!pageMatched) {
      setErrorCode("202");
    }

    if (isCompleted === "c27yxbi09r0jmfemvpjopsov") {
      setErrorCode("101");
    }
  }, [blogId]);

  const hashPageIdentifier = (blogId, page) => {
    const today = new Date().toISOString().slice(0, 10);
    const hash = crypto.SHA256(blogId + today + page).toString();
    return hash;
  };

  const handleNextClick = () => {
    if (!disabled) {
      const nextPage = currentPage + 1;
      const nextPageHash = hashPageIdentifier(blogId, nextPage);
      if (currentPage !== totalPages - 1) {
        localStorage.setItem("currentPageHash", nextPageHash);
        setCurrentPage(nextPage);
        setDisabled(true);
        window.location.href = nextUrl;
      } else if (currentPage === totalPages - 1 ){
        setCurrentPage(nextPage);
        setDisabled(true);
      }
    }
  };

  useEffect(() => {
    if (count === 0) {
      setDisabled(false);
    }
    if (currentPage === totalPages) {
      const today = new Date().toISOString().slice(0, 10);
      const promoCode = crypto.SHA256(blogId + today + ipAddress).toString();
      setCode(promoCode);
      localStorage.setItem(
        "c27yxbi09r0jmfemvpjopsov",
        "c27yxbi09r0jmfemvpjopsov"
      );
      localStorage.setItem(
        "c27yxbi09r0jmfem53opsov",
        "c27yxbi09r0jmfemrh5pjopsov"
      );
    }
  }, [count, currentPage, totalPages, ipAddress, blogId]);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy code: ", error);
      });
  };

  return (
    <div>
      <div className="count-code-container">
        {errorCode ? (
          <div>Error code {errorCode}</div>
        ) : (
          <div className="count-code-page">
            <div className="countdown">00 : {count}s</div>
            <div className="page-info">Visited {currentPage} pages</div>
            {code && (
              <>
                <div>Congrat your promo code is:</div>
                <div className="code-copy">
                  <div className="code-container">{code}</div>
                  <div
                    className="copy-button"
                    onClick={() => copyToClipboard(code)}
                  >
                    {copied ? "promo copied" : "copy"}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div className="nextprev-container">
        {hasPrevPost && (
          <a href={prevUrl} className="nextprev-button">
            {" "}
            <span className="nextprev-icon">
              <PrevDoubleIcon />
            </span>
            Previous
          </a>
        )}
        {hasNextPost && (
          <div
            className={`nextprev-button ${
              disabled ? "disabled opacity-65" : ""
            }`}
            onClick={handleNextClick}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
          >
            {currentPage === totalPages - 1 ? "Get Code" : "Next"}
            <span className="nextprev-icon">
              <NextDoubleIconx />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const Post = ({ ipAddress }) => {
  const { blogId, posts } = useGeneralBrain();
  const { slug } = useParams();

  useEffect(() => {
    if (slug && posts) {
      const post = posts.find((post) => slug === post.slug);
      if (post) {
        document.title = post.title;
      }

      window.scrollTo(0, 0);
    }
  }, [slug, posts]);

  const post = posts.find((post) => post.slug === slug);

  if (!post) {
    return <div>Post not found!</div>;
  }

  let PostComponent;

  if (post) {
    PostComponent = post.file
      ? require(`../mdx/posts/${blogId}/${post.file}.mdx`).default
      : null;
  }

  if (!PostComponent) {
    return <div>Post not found!</div>;
  }

  const currentIndex = posts.findIndex((p) => p.id === post.id);
  const prevPost = posts[currentIndex - 1];
  const prevUrl = prevPost ? prevPost.url : null;
  const nextPost = posts[currentIndex + 1];
  const nextUrl = nextPost ? nextPost.url : null;

  const hasPrevPost = prevUrl !== null;
  const hasNextPost = nextUrl !== null;

  return (
    <>
      <title>{post.title}</title>

      <div>
        <div className="all-blog-subnav-title">
          <SubNavHome />
          <a href="/blog" className="subnav-home dart">
            Blog
          </a>{" "}
          <span className="subnav-icon">
            {" "}
            <NextDoubleIcon />{" "}
          </span>
          <span className="all-blog-title">{post.title}</span>
        </div>

        <div className="post-children">
          <div className=" post-first-child">
            <h1 className="blog-title">{post.title}</h1>
            <div className="post-card-list-container-desc">
              <div className="post-card-list-container-icon-blogname">
                <span className="post-card-list-container-icon">
                  <UserIcon />
                </span>
                <p>{post.author}</p>
              </div>
              <div className="post-card-list-container-icon-date">
                <span className="post-card-list-container-icon">
                  <CalenderIcon />
                </span>
                <p>{post.date}</p>
              </div>
              <div className="post-card-list-container-icon-date fontb">
                <span className="post-card-list-container-icon">
                  <CategoryIcon />
                </span>
                <p>{post.category}</p>
              </div>
            </div>
            <TableOfContents />
            <div className="post-content">
              <MDXProvider>
                <PostComponent />
              </MDXProvider>
            </div>
            <Banner468x60x1 />
            <NextPrev
              hasPrevPost={hasPrevPost}
              hasNextPost={hasNextPost}
              prevUrl={prevUrl}
              nextUrl={nextUrl}
              ipAddress={ipAddress}
            />
          </div>
          <div className="post-last-child">
            <Banner300x250x1 />
            <Share />
            <Banner320x50x1 />
            <SearchEngine />
            <Banner160x300x1 />
            <RecentPosts />
            <Banner300x250x2 />
            <CategoryLists />
            <Banner160x600x1 />
          </div>
        </div>
      </div>
    </>
  );
};

const NotFound = () => {
  return <div>NotFound</div>;
};

const Engine = ({ id, name, type, card: Card, path, slug }) => {
  const { BlogName, blogId } = useGeneralBrain();

  <Helmet>
    <meta charSet="utf-8" />
    <link rel="icon" href="/logo.png" />
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-DYTWTZ5Z28"
    ></script>
    <script>
      {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());

            gtag("config", "G-DYTWTZ5Z28");
          `}
    </script>
    <title>{`${BlogName}`}</title>
    <meta name="description" content={`personal blog ${BlogName}`} />
    <meta
      property="og:url"
      content="https://blog-template-three-id.web.app/how-to-make-money-on-tiktok-in-nigeria-2024"
    />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={BlogName} />
    <meta property="og:description" content={`personal blog ${BlogName}`} />
    <meta
      property="og:image"
      content="https://ogcdn.net/6064b869-74ed-4eb9-b76c-0b701ffe7e6b/v4/blog-template-three-id.web.app/Blog%20template%20three/https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fdocuments%2F56188dc2-e3c3-4ce5-a8b1-1323953e37b9.jpg%3Ftoken%3DJFlihAXHC-Vn8g116odfvmJ_eBQBXHEEmfaU7-Y2c0M%26height%3D800%26width%3D1200%26expires%3D33240097602/og.png"
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="blog-template-three-id.web.app" />
    <meta
      property="twitter:url"
      content="https://blog-template-three-id.web.app/how-to-make-money-on-tiktok-in-nigeria-2024"
    />
    <meta name="twitter:title" content="Blog template three" />
    <meta name="twitter:description" content={`personal blog ${BlogName}`} />
    <meta
      name="twitter:image"
      content="https://ogcdn.net/6064b869-74ed-4eb9-b76c-0b701ffe7e6b/v4/blog-template-three-id.web.app/Blog%20template%20three/https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fdocuments%2F56188dc2-e3c3-4ce5-a8b1-1323953e37b9.jpg%3Ftoken%3DJFlihAXHC-Vn8g116odfvmJ_eBQBXHEEmfaU7-Y2c0M%26height%3D800%26width%3D1200%26expires%3D33240097602/og.png"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta name="description" content={`personal blog ${BlogName}`} />
    <link rel="apple-touch-icon" href="/logo.png" />
  </Helmet>;

  const [ipAddress, setIpAddress] = useState(null);
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source");
    console.log(source, "source");
    console.log(urlParams, "urlParams");

    if (source) {
      // Store the source in local storage
      localStorage.setItem("source", source);
    }
  }, []);

  const isFromGoogle = document.referrer;
  console.log(isFromGoogle, "googletagmanager");

  const components = {};
  let PostComponent = null;
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "dark";
  });

  useEffect(() => {
    document.title = `${name} - ${BlogName}`;
    window.scrollTo(0, 0);
  }, [name, BlogName]);

  if (type === "mdx") {
    PostComponent = require(`../mdx${path}.mdx`).default;
  }

  return (
    <div className={theme}>
      <div className="App">
        <div className="container">
          <div className="wrapper">
            <Navbar theme={theme} setTheme={setTheme} />
            <Banner728x90x1 />

            <PopUnderx1 />
            <div className="blog-container">
              <div className="all-blog-subnav-title">
                <SubNavHome />
                <span className="all-blog-title">{name}</span>
              </div>
              <h1 className="all-heading">{name}</h1>
              {type === "mdx" && (
                <div className="post-content">
                  <TableOfContents />
                  <MDXProvider components={components}>
                    <PostComponent />
                  </MDXProvider>
                </div>
              )}
              <SocialBarx1 />
              {type === "component" && (
                <div className="components-content">
                  <Card slug={slug} ipAddress={ipAddress} blogId={blogId} />
                </div>
              )}
            </div>
            <Footer />
            <CookieConsent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Engine id={"home"} name={"Home"} card={Home} type={"component"} />
          }
        />
        <Route
          path="/category/:category"
          element={
            <Engine
              id={"category"}
              name={"Category"}
              card={Category}
              type={"component"}
            />
          }
        />
        <Route
          path="/blog"
          element={
            <Engine id={"blog"} name={"Blog"} card={Blog} type={"component"} />
          }
        />
        <Route
          path="/about"
          element={
            <Engine
              id={"about"}
              name={"About"}
              path={"/pages/About"}
              type={"mdx"}
            />
          }
        />
        <Route
          path="/search"
          element={
            <Engine
              id={"search"}
              name={"Search"}
              card={SearchEngine}
              type={"component"}
            />
          }
        />

        <Route
          path="/category"
          element={<Navigate href="/category/Style" replace />}
        />
        <Route
          path="/contact"
          element={
            <Engine
              id={"contact"}
              name={"Contact"}
              path={"/pages/Contact"}
              type={"mdx"}
            />
          }
        />
        <Route
          path="/advertise"
          element={
            <Engine
              id={"advertise"}
              name={"Advertise"}
              path={"/pages/Advertise"}
              type={"mdx"}
            />
          }
        />
        <Route
          path="/affiliate"
          element={
            <Engine
              id={"affiliate"}
              name={"Affiliate"}
              path={"/pages/Affiliate"}
              type={"mdx"}
            />
          }
        />
        <Route
          path="/disclaimer"
          element={
            <Engine
              id={"disclaimer"}
              name={"disclaimer"}
              path={"/pages/Disclaimer"}
              type={"mdx"}
            />
          }
        />
        <Route
          path="/privacy"
          element={
            <Engine
              id={"privacy"}
              name={"Privacy"}
              path={"/pages/Privacy"}
              type={"mdx"}
            />
          }
        />
        <Route
          path="/terms"
          element={
            <Engine
              id={"terms"}
              name={"Terms"}
              path={"/pages/Terms"}
              type={"mdx"}
            />
          }
        />
        <Route
          path="/*"
          element={
            <Engine
              id={"NotFound"}
              name={"NotFound"}
              card={NotFound}
              type={"component"}
            />
          }
        />

        <Route
          path={"/:slug"}
          element={
            <Engine id={"post"} name={"Post"} card={Post} type={"component"} />
          }
        />
      </Routes>
    </Router>
  );
}
