
export const BlogName = "SEEDSTIMES";
export const FeaturedText = "Embark on a journey of growth and transformation. Uncover the seeds of change and nurture a life filled with purpose."

export const projectId = 'blog-3';
export const api = '0115a147dda703c33d90268e2caa95b9';
export const SocialBar ='//pl23201775.highcpmgate.com/1d/3d/c7/1d3dc7b08376274f0553918258900761.js'
export const PopUnder ='//pl23201789.highcpmgate.com/e0/41/5e/e0415e65152c613b15ff00414459d236.js'
export const DirectLink = 'https://www.highcpmgate.com/hx0i5qwy3?key=5dd29cda2ef9f7ed08cc1d64a6a84f20'
export const NativeBanner = "//pl23201887.highcpmgate.com/93520ca5ff1f0df3d8f7956b2df0f713/invoke.js"
export const NativeBannerId ="container-93520ca5ff1f0df3d8f7956b2df0f713"
export const Bannar300x250 =  'a2953f7d571a04be1a7c8a09ef2a5372'
export const Bannar160x600 = '1e388bce3290a2087820a1b32b57ceb1'
export const Bannar728x90 =  'c292931ae041f25e45bea7c7aa543513'
export const Bannar468x60 =  'bee769e688c2dc19fa5ac5a1bf50c934'
export const Bannar320x50  =  'bfef8e1e373463ca3dd62073ae9a15e4'
export const Bannar160x300  =  'e95101cd12091cb744602d1849e34030'


