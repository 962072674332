
export const BlogName = "GISTNEWS";

export const FeaturedText = "Discover small steps for big changes. Explore insights, inspiration, and actionable tips for a fulfilling life."

export const projectId = 'blog-5';
export const api = '0c91db1bc31f4f8f9ed35c74dfe92c5f';
export const SocialBar ='//pl23201767.highcpmgate.com/1a/95/44/1a9544f3c19179c31795c7bc7826d029.js'
export const PopUnder ='//pl23201753.highcpmgate.com/b1/e3/04/b1e304b8003238a7bf942936444b3c0f.js'
export const DirectLink = 'https://www.highcpmgate.com/vs6enzb3cq?key=955d44629e25c571e4e2995729ac74a4'
export const NativeBanner = "//pl23201873.highcpmgate.com/4ca0bae769a8e7bbb59c4e3f6ef855e5/invoke.js"
export const NativeBannerId ="container-4ca0bae769a8e7bbb59c4e3f6ef855e5"
export const Bannar300x250 = 'e7376516fb2a227ad168f84d71fa80b2'
export const Bannar160x600 = '3a7c1ccc29f95cf1b13175ce4b866196'
export const Bannar728x90 =  '711f672a1fd0d461cf9f9d523fe8a793'
export const Bannar468x60 =  '52edffeb989fe687f6d83a4a2fdf013c'
export const Bannar320x50  =  'f65fd04e1cc0850ce12bf94ee09843f0'
export const Bannar160x300  = 'd287757f639570515da3094ff5f7cf90'