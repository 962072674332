const titleToSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "");
};

export const posts = [
  {
    id: 1,
    img: "/AllAssets/post1.png",
    file: "post1",
    title: "Demystifying Mutual Funds: A Beginner's Guide",
    author: "Emily Johnson",
    description:
      "Learn the basics of mutual funds, including types, benefits, and how to start investing, to achieve your financial goals.",
    category: "finance",
    date: "2024-01-11",
  },
  {
    id: 2,
    img: "/AllAssets/post2.png",
    file: "post2",
    title: "The Power of Compounding: How to Make Your Money Grow",
    author: "David Smith",
    description:
      "Discover the magic of compounding and learn how to use it to your advantage to build wealth and secure your financial future.",
    category: "finance",
    date: "2024-01-23",
  },
  {
    id: 3,
    img: "/AllAssets/post3.png",
    file: "post3",
    title: "Navigating the Stock Market: Tips for New Investors",
    author: "Sophie Brown",
    description:
      "Get started in the stock market with confidence! Learn essential tips and strategies for new investors to make informed decisions.",
    category: "finance",
    date: "2024-01-21",
  },
  {
    id: 4,
    img: "/AllAssets/post4.png",
    file: "post4",
    title: "Understanding Tax Saving Investments",
    author: "Michael Thompson",
    description:
      "Explore tax-saving investment options, including retirement plans, mutual funds, and insurance, to minimize your tax liability and maximize returns.",
    category: "finance",
    date: "2024-01-21",
  },
  {
    id: 5,
    img: "/AllAssets/post5.png",
    file: "post5",
    title: "The Rise of Digital Banking",
    author: "Emma Wilson",
    description:
      "Discover how digital banking is transforming the banking industry and revolutionizing the way we manage our finances.",
    category: "finance",
    date: "2024-01-23",
  },
  {
    id: 6,
    img: "/AllAssets/post6.png",
    file: "post6",
    title: "A Beginner's Guide to Financial Planning",
    author: "Alexander Martinez",
    description:
      "Learn the fundamentals of financial planning, including budgeting, saving, investing, and retirement planning, to achieve your financial goals.",
    category: "finance",
    date: "2024-03-03",
  },
  {
    id: 7,
    img: "/AllAssets/post7.png",
    file: "post7",
    title: "The Impact of GST on the Global Economy",
    author: "Sophia Davis",
    description:
      "Explore the implications of GST on the global economy, including its benefits, challenges, and the way forward for GST reforms.",
    category: "finance",
    date: "2024-01-27",
  },
  {
    id: 8,
    img: "/AllAssets/post8.png",
    file: "post8",
    title: "Investing in Gold: A Comprehensive Guide",
    author: "Daniel Brown",
    description:
      "Learn everything you need to know about investing in gold, including different investment options, their pros and cons, and tips for beginners.",
    category: "finance",
    date: "2024-01-27",
  },
  {
    id: 9,
    img: "/AllAssets/post9.png",
    file: "post9",
    title: "The Future of Fintech",
    author: "Olivia Taylor",
    description:
      "Explore the latest trends and innovations in fintech and how they are shaping the future of finance.",
    category: "finance",
    date: "2024-01-23",
  },
  {
    id: 10,
    img: "/AllAssets/post10.png",
    file: "post10",
    title: "Managing Debt: Tips for Young Professionals",
    author: "James Wilson",
    description:
      "Learn practical tips and strategies to manage debt effectively and achieve financial freedom as a young professional.",
    category: "finance",
    date: "2024-01-23",
  },
].map((post) => ({
  ...post,
  slug: titleToSlug(post.title),
  url: `/${titleToSlug(post.title)}`,
}));
