export const BlogName = "AWALLSTREET-ID";

export const FeaturedText = "Discover small steps for big changes. Explore insights, inspiration, and actionable tips for a fulfilling life."

export const projectId = 'blog-8';
export const api = '28c51f38df3aca6a783d5734a49d080a';
export const SocialBar ='//pl23246074.highcpmgate.com/ac/f1/71/acf171238d1dab1cec2a8c53f22dd048.js'
export const PopUnder ='//pl23245634.highcpmgate.com/ea/75/dd/ea75dd1a38c0faac9a26473473b11bdd.js'
export const DirectLink = 'https://www.highcpmgate.com/sht4sd0z?key=444da560301c150a1f1b072be2de8e9d'
export const NativeBanner = "//pl23245767.highcpmgate.com/735c37d4a46a9c7fb29d4b1bae727ffa/invoke.js"
export const NativeBannerId ="container-735c37d4a46a9c7fb29d4b1bae727ffa"
export const Bannar300x250 =  'ef675d9762dd81c3c0b13b6e51715edd'
export const Bannar160x600 ='7cd103ae604d16daca96501297c20c4a'
export const Bannar728x90 =  '3ed15eadd29f5af1b1202ae912eb631c'
export const Bannar468x60 =  '88fc52677472462cc2e68b5bbbd50e55'
export const Bannar320x50  =  'ef34d211f5610f659aa087cd7c9e8b76'
export const Bannar160x300  =  '073c71e3f21268b58f958de2edea4f66'