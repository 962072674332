import React from "react";
import ReactDOM from "react-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import AppRouter from "./engine/Engine";
import "../src/engine/Engine.css";
import { GeneralBrainProvider } from "./engine/GeneralBrain";

function App() {
  return (
    <div>
      <GeneralBrainProvider>
        <AppRouter />
      </GeneralBrainProvider>
    </div>
  );
}

export default App;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
