const titleToSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "");
};

export const posts = [
 
{
  id: 1,
  img: "/AllAssets/post1.png",
  file: "post1",
  title: "10 Habits of Successful Wealth Builders",
  author: "Aaradhya Singh",
  description: "Discover the habits and behaviors that successful wealth builders practice consistently. From setting financial goals and living below their means to continuous learning and disciplined saving, learn how adopting these habits can help you build and preserve wealth over time.",
  category: "Wealth ",
  date: "2024-01-19",
},
{
  id: 2,
  img: "/AllAssets/post2.png",
  file: "post2",
  title: "Top 5 Personal Finance Books to Master Wealth Management",
  author: "Aaradhya Singh",
  description: "Explore a curated list of personal finance books that offer valuable insights into wealth management and financial success. From timeless classics to contemporary bestsellers, discover resources that can empower you with knowledge and strategies to take control of your finances and build lasting wealth.",
  category: "Wealth",
  date: "2024-01-19",
},
{
  id: 3,
  img: "/AllAssets/post3.png",
  file: "post3",
  title: "Top 5 Savings Strategies for Financial Success",
  author: "Christopher Martinez",
  description: "Explore the best strategies to bolster your savings and achieve your financial goals. From automating your savings and creating a budget to prioritizing needs over wants and exploring high-yield savings accounts, discover effective ways to boost your savings and secure your financial future.",
  category: "Savings",
  date: "2024-01-23",
},
{
  id: 4,
  img: "/AllAssets/post4.png",
  file: "post4",
  title: "5 Reasons Why Emergency Funds are Essential",
  author: "Mia Weber",
  description: "Learn why having an emergency fund is crucial for financial stability. From covering unexpected expenses and avoiding debt to providing peace of mind and protecting your long-term financial goals, discover the compelling reasons why everyone should prioritize building and maintaining an emergency fund.",
  category: "Savings",
  date: "2024-01-05",
},
{
  id: 5,
  img: "/AllAssets/post5.png",
  file: "post5",
  title: " Top 20 Tips for Saving Money",
  author: "Mia Weber",
  description: "Dive into the ultimate guide for building a nest egg and securing your financial future. From cutting expenses and negotiating bills to setting savings goals and investing wisely, uncover practical tips and strategies to supercharge your savings and achieve financial independence.",
  category: "Savings",
  date: "2024-01-23",
},
{
  id: 6,
  img: "/AllAssets/post6.png",
  file: "post6",
  title: "10 Ways to Save Money on Everyday Expenses",
  author: "Christopher Martinez",
  description: "Discover practical ways to save money on your everyday expenses and stretch your budget further. From meal planning and DIY projects to comparison shopping and loyalty programs, explore simple yet effective strategies to reduce costs and boost your savings without sacrificing your lifestyle.",
  category: "Savings",
  date: "2024-01-23",
},
{
  id: 7,
  img: "/AllAssets/post7.png",
  file: "post7",
  title: "The Power of Networking in Online Work",
  author: "Christopher Martinez",
  description: "Explore the importance of networking in the realm of online work and freelancing. From leveraging social media platforms and online communities to attending virtual networking events and joining industry-specific groups, discover how building meaningful connections can open doors to new opportunities and professional growth.",
  category: "Online work",
  date: "2024-01-20",
},
{
  id: 8,
  img: "/AllAssets/post8.png",
  file: "post8",
  title: "Top 5 Online Courses to Boost Your Skills for Remote Work",
  author: "Christopher Martinez",
  description: "Discover a curated selection of online courses designed to enhance your skills for remote work success. From digital marketing and project management to coding and graphic design, explore courses offered by reputable platforms that empower you to upskill and stay competitive in the online job market.",
  category: "Online work",
  date: "2024-01-23",
},
{
  id: 9,
  img: "/AllAssets/post9.png",
  file: "post9",
  title: "5 Reasons to Consider Remote Internships for Skill Development",
  author: "Benjamin Lee",
  description: "Explore the benefits of remote internships as a valuable avenue for skill development and professional growth. From gaining practical experience and building a professional network to exploring diverse industries and honing remote work skills, discover why remote internships are worth considering for aspiring professionals.",
  category: "Online work",
  date: "2024-01-23",
},
{
  id: 10,
  img: "/AllAssets/post10.png",
  file: "post10",
  title: "Navigating the World of Online Freelancing: A Beginner's Guide",
  author: "Benjamin Lee",
  description: "Embark on a journey into the world of online freelancing with this comprehensive beginner's guide. From choosing the right niche and setting up a freelance profile to finding clients and managing projects, learn the essential steps and strategies to kickstart your freelancing career and thrive in the digital workspace.",
  category: "Online work",
  date: "2024-01-23",
},
].map((post) => ({
  ...post,
  slug: titleToSlug(post.title),
  url: `/${titleToSlug(post.title)}`,
}));
