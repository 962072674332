const titleToSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "");
};

export const posts = [
  {
    id: 1,
    img: "/AllAssets/post1.png",
    file: "post1",
    title: "10 Budgeting Apps to Manage Your Finances Like a Pro",
    author: "Felix Wagner",
    description:
      "Explore the best budgeting apps available to help you take control of your finances. From expense tracking and goal setting to automated savings and bill reminders, these apps offer features to simplify budgeting and empower you to make smarter financial decisions.",
    category: "Budgeting",
    date: "2024-01-11",
  },
  {
    id: 2,
    img: "/AllAssets/post2.png",
    file: "post2",
    title: "5 Reasons Why Budgeting is Essential for Financial Success",
    author: "Felix Wagner",
    description:
      "Discover the importance of budgeting in achieving financial stability and success. From tracking expenses and managing debt to saving for goals and preparing for emergencies, learn why budgeting is a foundational practice for building wealth and securing your financial future.",
    category: "Budgeting",
    date: "2024-01-23",
  },
  {
    id: 3,
    img: "/AllAssets/post3.png",
    file: "post3",
    title: "Ultimate Guide to Creating a Budget: Step-by-Step Instructions",
    author: "Felix Wagner",
    description:
      "Get started on your budgeting journey with this comprehensive guide. From assessing your income and expenses to setting financial goals and creating a budget plan, learn how to develop a personalized budget that aligns with your priorities and helps you achieve your financial objectives.",
    category: "Budgeting",
    date: "2024-01-21",
  },
  {
    id: 4,
    img: "/AllAssets/post4.png",
    file: "post4",
    title: "5 Budget-Friendly Ways to Travel on a Tight Budget",
    author: "Jonas Fischer",
    description:
      "Discover how to travel affordably without breaking the bank with these budget-friendly tips. From choosing budget destinations and finding discounted accommodations to minimizing transportation costs and embracing free activities, learn how to make the most of your travel budget and explore the world on a dime.",
    category: "Budgeting",
    date: "2024-01-21",
  },
  {
    id: 5,
    img: "/AllAssets/post5.png",
    file: "post5",
    title: "5 Ways to Boost Your Savings",
    author: "Jonas Fischer",
    description: "",
    category: "Budgeting",
    date: "2024-01-23",
  },
  {
    id: 6,
    img: "/AllAssets/post6.png",
    file: "post6",
    title: "10 Books to Improve Your Financial Literacy",
    author: "Felix Wagner",
    description:
      "Explore the best books on personal finance and investing to enhance your financial literacy. From beginner-friendly guides to advanced investment strategies, these books offer valuable insights and knowledge to help you make informed decisions and achieve financial success.",
    category: "Financial Literacy",
    date: "2024-03-03",
  },
  {
    id: 7,
    img: "/AllAssets/post7.png",
    file: "post7",
    title: "5 Reasons Why Financial Literacy is Crucial for Millennials",
    author: "Jonas Fischer",
    description:
      "Discover why financial literacy is essential for millennials in today's complex economy. From managing student loans and building credit to investing for the future and planning for retirement, learn how improving financial literacy can empower millennials to navigate financial challenges and achieve their goals.",
    category: "Financial Literacy",
    date: "2024-01-27",
  },
  {
    id: 8,
    img: "/AllAssets/post8.png",
    file: "post8",
    title: "The Ultimate Guide to Teaching Financial Literacy to Kids",
    author: "Nisha Gupta",
    description:
      "Empower the next generation with essential financial skills and knowledge with this comprehensive guide to teaching financial literacy to kids. From budgeting and saving to understanding credit and investing, learn how to instill lifelong money management habits in children and teens.",
    category: "Financial Literacy",
    date: "2024-01-27",
  },
  {
    id: 9,
    img: "/AllAssets/post9.png",
    file: "post9",
    title: "5 Common Myths About Financial Literacy Debunked",
    author: "Felix Wagner",
    description:
      "Challenge misconceptions and debunk common myths surrounding financial literacy with this insightful article. From the belief that financial literacy is only for the wealthy to the misconception that investing is too complex, learn the truth behind these myths and why financial literacy is accessible to everyone.",
    category: "Financial Literacy",
    date: "2024-01-23",
  },
  {
    id: 10,
    img: "/AllAssets/post10.png",
    file: "post10",
    title: "5 Financial Literacy Activities for All Ages",
    author: "Aaradhya Singh",
    description:
      "Engage learners of all ages with these interactive and educational financial literacy activities. From games and simulations to hands-on projects and real-life scenarios, discover creative ways to teach and reinforce essential money management skills and concepts for lifelong financial success.",
    category: "Financial Literacy",
    date: "2024-01-23",
  },
].map((post) => ({
  ...post,
  slug: titleToSlug(post.title),
  url: `/${titleToSlug(post.title)}`,
}));
