export const BlogName = "BIGFISHONLY-ID";
export const FeaturedText = "Discover small steps for big changes. Explore insights, inspiration, and actionable tips for a fulfilling life."

export const projectId = 'blog-10';
export const api = '561b3b65ef6f3eaaa031c053f29aaf9c';
export const SocialBar ='//pl23245924.highcpmgate.com/ad/fc/2b/adfc2b3d85adc2ce80d40b60acd7bc50.js'
export const PopUnder ='//pl23245643.highcpmgate.com/d0/92/00/d09200ca726aeff449437fa4410be067.js'
export const DirectLink = 'https://www.highcpmgate.com/k0s1rz7n?key=028f6ab233ea62940c86e88ccfc238e9'
export const NativeBanner = "//pl23245771.highcpmgate.com/706a79eac7faeb1c0f52ee03b3a8decb/invoke.js"
export const NativeBannerId ="container-706a79eac7faeb1c0f52ee03b3a8decb"
export const Bannar300x250 =  '8975a3e7c0258bc1e3568eeaca312ac0'
export const Bannar160x600 = '71aed96245e226eae15dff7b768f4100'
export const Bannar728x90 = 'ea039264d6d14dff1f17a9f9bf042da2'
export const Bannar468x60 =  '1e8f61bb6b816bb4548dd6060182cffb'
export const Bannar320x50  =  '555054de9470d248da72210f42f0d10c'
export const Bannar160x300  =  '3c432f07eb8de70920d1a5cae790d00a'