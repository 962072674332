export const BlogName = "Blog template";
export const FeaturedText = "Discover small steps for big changes. Explore insights, inspiration, and actionable tips for a fulfilling life."
export const blogId = 'blog-0';

export const api = 'c69b36d7db357ed7cbbs343c45e9s';
export const SocialBar ='//pl23189617.hismgate.com/b3/b0/2b/b3b02be924496c6f092037c9881s.js'
export const PopUnder ='//pl23189692.highsmgate.com/6b/49/41/6b494185801faf23bc1da3esf442.js'
export const DirectLink = 'https://www.highsgate.com/uny1b564?key=8f99606b74asa3ec9e5169845f'
export const NativeBanner = "//pl23189777.highcsd7454bfd6fb0sd5158da893"
export const Bannar300x250 = '93e33946fc005a2s3sdb4bc'
export const Bannar160x600 ='5f4aba5f59dab6ba2ds02e2ab2b8'
export const Bannar728x90 =  'a39f06c45023ees89818f952566'
export const Bannar320x50  = 'b83aaef8055dc8e2s4bfb7d56b311'
export const Bannar160x300  = '23331c69eaded348sa37edb0a13c9a'