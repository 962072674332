export const BlogName = "GEEKTRENDS";

export const FeaturedText = "Discover small steps for big changes. Explore insights, inspiration, and actionable tips for a fulfilling life."
export const projectId = 'blog-4';
export const api = 'd8156c1567187f862673e41b3ea3ebad';
export const SocialBar ='//pl23201740.highcpmgate.com/0f/7d/19/0f7d199e34f82fd22938521549e932cd.js'
export const PopUnder ='//pl23201747.highcpmgate.com/1b/cf/7a/1bcf7a297435f5686eeed9f25766e630.js'
export const DirectLink = "//pl23201887.highcpmgate.com/93520ca5ff1f0df3d8f7956b2df0f713/invoke.js"
export const NativeBanner = "//pl23201873.highcpmgate.com/4ca0bae769a8e7bbb59c4e3f6ef855e5/invoke.js"
export const NativeBannerId ="container-93520ca5ff1f0df3d8f7956b2df0f713"
export const Bannar300x250 =  'abb9b3e64a778aba3aa5cf0305cf2762'
export const Bannar160x600 = 'c951f38db89c428e1739e7e5f6fc96e4'
export const Bannar728x90 = '0954a9cad7b2077baf128f0c4c6b4834'
export const Bannar468x60 =  '1492bd27f9ed9f72dd9272636006c5ed'
export const Bannar320x50  =  '3a775777f364606316db6f27d408ae8a'
export const Bannar160x300  =  '52e749043e483f13b2c84b67aac77b58'