export const domains = [
  {
    domain: "localhost",
    blogId: "blog-0",
  },
  {
    domain: "acentatatime.online",
    blogId: "blog-1",
  },
  {
    domain: "moneyning.online",
    blogId: "blog-2",
  },
  {
    domain: "seedstimes.online",
    blogId: "blog-3",
  },
  {
    domain: "geektrends.online",
    blogId: "blog-4",
  },
  {
    domain: "gistnews.online",
    blogId: "blog-5",
  },
  {
    domain: "afinancespot-id.web.app",
    blogId: "blog-6",
  },
  {
    domain: "assetalchemy-id.web.app",
    blogId: "blog-7",
  },
  {
    domain: "awallstreet-id.firebaseapp.com",
    blogId: "blog-8",
  },
  {
    domain: "bellinfos-id.web.app",
    blogId: "blog-9",
  },
  {
    domain: "bigfishonly-id.firebaseapp.com",
    blogId: "blog-10",
  },
];
