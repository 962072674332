const titleToSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "");
};

export const posts = [
  {
  id: 1,
  img: "/AllAssets/post1.png",
  file: "post1",
  title: "Top 10 Freelance Platforms for Online Work",
  author: "Christopher Martinez",
  description: "Explore the leading freelance platforms where you can find diverse online work opportunities. From writing and graphic design to programming and virtual assistance, discover the best platforms to kickstart your freelancing career and earn income remotely.",
  category: "Online work",
  date: "2024-01-11",
},
{
  id: 2,
  img: "/AllAssets/post2.png",
  file: "post2",
  title: "5 Reasons Why Remote Work is the Future",
  author: "Christopher Martinez",
  description: "Discover why remote work is gaining momentum and shaping the future of work. From flexibility and cost savings to increased productivity and global talent access, explore the compelling reasons why remote work is here to stay and revolutionize traditional work models.",
  category: "Online work",
  date: "2024-01-23",
},
{
  id: 3,
  img: "/AllAssets/post3.png",
  file: "post3",
  title: "The Ultimate Guide to Freelance Writing Jobs Online",
  author: "Christopher Martinez",
  description: "Dive into the world of freelance writing and discover a plethora of online opportunities. From content creation and copywriting to blogging and journalism, learn how to navigate the freelance writing landscape and build a successful career from anywhere in the world.",
  category: "Online work",
  date: "2024-01-21",
},
{
  id: 4,
  img: "/AllAssets/post4.png",
  file: "post4",
  title: "Top 10 Websites for Remote Job Opportunities",
  author: "Jonas Fischer",
  description: "Explore the best websites offering remote job opportunities across various industries. From full-time remote positions to freelance gigs and part-time roles, uncover platforms that connect you with remote-friendly companies seeking talented professionals to join their distributed teams.",
  category: "Online work",
  date: "2024-01-21",
},
{
  id: 5,
  img: "/AllAssets/post5.png",
  file: "post5",
  title: "5 Essential Skills for Success in Online Work",
  author: "Jonas Fischer",
  description: "Learn the key skills needed to thrive in the world of online work. From time management and communication skills to adaptability and self-motivation, discover the essential competencies that will help you excel in remote work environments and stand out as a valuable asset to employers.",
  category: "Online work",
  date: "2024-01-23",
},
{
  id: 6,
  img: "/AllAssets/post6.png",
  file: "post6",
  title: "Top 5 Online Work-from-Home Ideas for Beginners",
  author: "Christopher Martinez",
  description: "Explore a diverse range of work-from-home ideas suitable for beginners. From online tutoring and virtual assistant roles to e-commerce entrepreneurship and affiliate marketing, discover lucrative opportunities to start earning income remotely with minimal investment and experience.",
  category: "Online work",
  date: "2024-03-03",
},
{
  id: 7,
  img: "/AllAssets/post7.png",
  file: "post7",
  title: "The Rise of Gig Economy: Exploring Online Freelancing Trends",
  author: "Jonas Fischer",
  description: "Delve into the gig economy phenomenon and its impact on the future of work. From the rise of freelancing platforms to the growing demand for gig workers, explore the latest trends shaping the online freelancing landscape and the opportunities they present for remote workers worldwide.",
  category: "Online work",
  date: "2024-01-27",
},
{
  id: 8,
  img: "/AllAssets/post8.png",
  file: "post8",
  title: "10 Tips for Balancing Work and Life in the Online World",
  author: "Nisha Gupta",
  description: "Discover practical strategies for achieving work-life balance while working online. From setting boundaries and managing distractions to prioritizing self-care and maintaining social connections, learn how to navigate the challenges of remote work and cultivate a fulfilling and sustainable lifestyle.",
  category: "Online work",
  date: "2024-01-27",
},
{
  id: 9,
  img: "/AllAssets/post9.png",
  file: "post9",
  title: "Top 10 Investment Strategies for Building Wealth",
  author: "Christopher Martinez",
  description: "Explore proven investment strategies to grow your wealth effectively. From stock market investing and real estate to passive income streams and retirement accounts, discover diverse approaches to maximize your financial returns and achieve long-term wealth accumulation.",
  category: "Wealth ",
  date: "2024-01-23",
},
{
  id: 10,
  img: "/AllAssets/post10.png",
  file: "post10",
  title: "5 Reasons Why Diversification is Key to Wealth Building",
  author: "Aaradhya Singh",
  description: "Learn why diversifying your investment portfolio is crucial for building wealth. From reducing risk and maximizing returns to capitalizing on market opportunities and preserving capital, discover the compelling reasons why diversification is essential for long-term financial success and security.",
  category: "Wealth ",
  date: "2024-01-23",
},

].map((post) => ({
  ...post,
  slug: titleToSlug(post.title),
  url: `/${titleToSlug(post.title)}`,
}));
