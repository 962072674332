import { createContext, useContext, useState, useEffect } from "react";
import { domains } from "../mdx/info";

const GeneralBrainContext = createContext();

export const GeneralBrainProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState(null);

  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  useEffect(() => {
    const currentDomain = window.location.hostname;

    const matchedDomain = domains.find(
      (domain) => currentDomain === domain.domain
    );
    const blogId = matchedDomain ? matchedDomain.blogId : "blog-0";

    if (blogId) {
      const posts = require(`../mdx/posts/${blogId}/Post.jsx`).posts;
      const {
        BlogName,
        FeaturedText,
        SocialBar,
        PopUnder,
        DirectLink,
        NativeBanner,
        NativeBannerId,
        Bannar300x250,
        Bannar160x600,
        Bannar728x90,
        Bannar468x60,
        Bannar320x50,
        Bannar160x300,
      } = require(`../mdx/posts/${blogId}/About.jsx`);

      setContextValue({
        blogId,
        posts,
        BlogName,
        FeaturedText,
        SocialBar,
        PopUnder,
        DirectLink,
        NativeBanner,
        NativeBannerId,
        Bannar300x250,
        Bannar160x600,
        Bannar728x90,
        Bannar468x60,
        Bannar320x50,
        Bannar160x300,
        ipAddress,
      });
    }
  }, [ipAddress]); // Empty dependency array ensures this effect runs only once after mounting

  if (!contextValue) {
    // You can return a loading indicator here if needed
    return null;
  }

  return (
    <GeneralBrainContext.Provider value={contextValue}>
      {children}
    </GeneralBrainContext.Provider>
  );
};

export const useGeneralBrain = () => {
  const context = useContext(GeneralBrainContext);
  if (!context) {
    throw new Error(
      "useGeneralBrain must be used within a GeneralBrainProvider"
    );
  }
  return context;
};
