const titleToSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-zA-Z0-9-]/g, "");
  };
  
  export const posts = [
    {
id: 1,
img: "/AllAssets/post1.png",
file: "post1",
title: "Best platform to hire top talents",
author: "Christopher Martinez",
description: "Discover the ultimate list of premier platforms for sourcing freelance talent and securing online projects. From seasoned professionals to niche specialists, these top websites offer a diverse pool of freelancers across industries. Streamline your search, find quality work, and tap into a global network of skilled professionals ready to deliver excellence.",
category: "freelancing",
date: "2024-01-23",
},
{
id: 2,
img: "/AllAssets/post2.png",
file: "post2",
title: "Freelancing Versus Full-Time Employment",
author: "Christopher Martinez",
description: "Dive into the age-old debate of freelancing versus full-time employment and uncover the definitive winner. Explore the benefits and drawbacks of each career path, from flexibility and autonomy to stability and benefits. Gain valuable insights to make informed decisions about your professional journey.",
category: "freelancing",
date: "2024-01-23",
},
{
id: 3,
img: "/AllAssets/post3.png",
file: "post3",
title: "A Comprehensive Guide of Essence of Freelancing",
author: "Christopher Martinez",
description: "Dive into the multifaceted world of freelancing and uncover its essence in this comprehensive guide. From the freedom of self-employment to the challenges of managing a solo career, discover what freelancing truly entails and gain valuable insights into this dynamic and evolving profession.",
category: "freelancing",
date: "2024-01-23",
},
{
id: 4,
img: "/AllAssets/post4.png",
file: "post4",
title: "Mastering Time Management: Essential Strategies for Freelancers",
author: "Jonas Fischer",
description: "Discover the crucial time management strategies every freelancer needs for success in 'Mastering Time Management: Essential Strategies for Freelancers'. Learn how to optimize your schedule, prioritize tasks effectively, and maximize productivity to achieve your goals and thrive in the dynamic world of freelancing. Unlock your full potential today!",
category: "freelancing",
date: "2024-01-23",
},
{
id: 5,
img: "/AllAssets/post5.png",
file: "post5",
title: "10 Tips for freelancer to Maintain Professionalism and Productivity ",
author: "Jonas Fischer",
description: "Navigating Client Relationships: Discover essential tips for maintaining professionalism and productivity in your freelance career. Learn how to effectively communicate with clients, set boundaries, and manage expectations to foster positive working relationships and achieve success in your projects",
category: "freelancing",
date: "2024-01-23",
},
{
id: 6,
img: "/AllAssets/post6.png",
file: "post6",
title: "Affiliate marketing tutorial for beginners (7 steps)",
author: "Christopher Martinez",
description: "Embark on your journey into affiliate marketing with this beginner-friendly tutorial. Discover seven essential steps to kickstart your affiliate marketing career, from choosing a niche to optimizing your campaigns. Learn the basics and start earning passive income through affiliate marketing today.",
category: "Affiliate Marketing",
date: "2024-01-23",
},
{
id: 7,
img: "/AllAssets/post7.png",
file: "post7",
title: "10 Common Mistakes people Made In Affiliates",
author: "Jonas Fischer",
description: "Discover the pitfalls to avoid in affiliate marketing with this insightful guide. Learn about the most common mistakes made by beginners and seasoned marketers alike, and gain valuable tips on how to navigate the affiliate landscape successfully to maximize your earning potential.",
category: "Affiliate Marketing",
date: "2024-01-23",
},
{
id: 8,
img: "/AllAssets/post8.png",
file: "post8",
title: "Unveiling the Secrets of High-Converting Affiliate Marketing Campaigns",
author: "Nisha Gupta",
description: "Unlock the mysteries behind successful affiliate marketing campaigns with this revealing guide. Discover the proven strategies, tactics, and insights that drive high conversion rates and maximize affiliate earnings. From audience targeting to content creation, unveil the secrets to crafting campaigns that captivate audiences and generate lucrative results.",
category: "Affiliate Marketing",
date: "2024-01-23",
},
{
id: 9,
img: "/AllAssets/post9.png",
file: "post9",
title: "Earning Passive Income with Affiliate Marketing: Tips and Tricks",
author: "Christopher Martinez",
description: "Dive into the world of passive income generation through affiliate marketing with this comprehensive guide. Discover expert tips and tricks to build sustainable revenue streams by leveraging affiliate partnerships effectively. Unlock the secrets to earning passive income while maximizing your potential in the affiliate marketing arena.",
category: "Affiliate Marketing",
date: "2024-01-23",
},
{
id: 10,
img: "/AllAssets/post10.png",
file: "post10",
title: "Cracking the Code of Affiliate Marketing: Insider Insights and Strategies",
author: "Aaradhya Singh",
description: "Unlock exclusive insider insights and strategies with 'Cracking the Code of Affiliate Marketing.' Discover the secrets to success in affiliate marketing, from industry experts' tips to proven strategies that will propel your affiliate business to new heights. Dive deep into the world of affiliate marketing and unlock your full potential.",
category: "Affiliate Marketing",
date: "2024-01-23",
},
{
id: 11,
img: "/AllAssets/post11.png",
file: "post11",
title: "Navigating Real Estate Freelancing: A Guide for Independent Agents",
author: "Aaradhya Singh",
description: "Dive into the dynamic world of real estate freelancing with our comprehensive guide. Discover essential strategies, tools, and insights to navigate the freelance landscape successfully. Whether you're a seasoned agent or just starting, unlock the secrets to thriving as an independent real estate professional.",
category: "Real Estate",
date: "2024-01-23",
},
{
id: 12,
img: "/AllAssets/post12.png",
file: "post12",
title: "Freelance Real Estate Writing: Crafting Compelling Property Descriptions",
author: "Aaradhya Singh",
description: "Learn the secrets to crafting captivating property descriptions in the world of freelance real estate writing. Dive into the nuances of language, imagery, and persuasion to entice potential buyers and elevate your real estate copywriting game.",
category: "Real Estate",
date: "2024-01-23",
},
{
id: 13,
img: "/AllAssets/post13.png",
file: "post13",
title: "Finding Success as a Freelance Real Estate Photographer",
author: "Christopher Martinez",
description: "Unlock the secrets to thriving as a freelance real estate photographer with our guide. Discover essential tips and strategies to elevate your skills, attract clients, and build a successful career in capturing captivating property images. Transform your passion for photography into a lucrative freelance venture in the dynamic real estate industry.",
category: "Real Estate",
date: "2024-01-23",
},
{
id: 14,
img: "/AllAssets/post14.png",
file: "post14",
title: "The Freelancer's Guide to Real Estate Transactions: Tips for Success",
author: "Mia Weber",
description: "Unlock the secrets to success in real estate transactions with our comprehensive guide for freelancers. Discover essential tips and strategies to navigate the intricacies of real estate deals, from negotiations to closings. Elevate your freelance career by mastering the art of facilitating seamless and successful real estate transactions.",
category: "Real Estate",
date: "2024-01-23",
},
{
id: 15,
img: "/AllAssets/post15.png",
file: "post15",
title: "Legal Considerations for Freelance Real Estate Consultants: What You Need to Know",
author: "Mia Weber",
description: "Dive into the legal essentials for freelance real estate consultants. Explore crucial considerations, from contracts to liability, ensuring compliance and protection in your consultancy. Arm yourself with vital knowledge to navigate legal landscapes confidently and safeguard your freelance consultancy practice.",
category: "Real Estate",
date: "2024-01-23",
},
{
id: 16,
img: "/AllAssets/post16.png",
file: "post16",
title: "Top 10 Cryptocurrencies to Watch in 2024",
author: "Christopher Martinez",
description: "Discover the top 10 cryptocurrencies poised to make waves in 2024. From established giants like Bitcoin to emerging contenders, explore the factors driving their potential growth and influence. Stay ahead of the curve with insights into the evolving landscape of digital assets and investment opportunities.",
category: "Cryptocurrency",
date: "2024-01-23",
},
{
id: 17,
img: "/AllAssets/post17.png",
file: "post17",
title: "Best 20 Resources for Learning About Cryptocurrency",
author: "Christopher Martinez",
description: "Discover the top 20 educational resources for delving into the world of cryptocurrency. From comprehensive guides and tutorials to online courses and forums, these curated resources provide invaluable insights and knowledge for beginners and experienced enthusiasts alike, empowering you to navigate the complex crypto landscape with confidence.",
category: "Cryptocurrency",
date: "2024-01-23",
},
{
id: 18,
img: "/AllAssets/post18.png",
file: "post18",
title: "5 Reasons Why Bitcoin Remains the King of Cryptocurrencies",
author: "Christopher Martinez",
description: "Discover why Bitcoin continues to reign supreme in the world of cryptocurrencies. From its first-mover advantage and widespread adoption to its scarcity and secure network, explore five compelling reasons why Bitcoin maintains its position as the undisputed king of digital assets.",
category: "Cryptocurrency",
date: "2024-01-23",
},
{
id: 19,
img: "/AllAssets/post19.png",
file: "post19",
title: "Best 10 Cryptocurrency Exchanges for Trading Altcoins",
author: "Benjamin Lee",
description: "Explore the top 10 cryptocurrency exchanges renowned for trading altcoins. From robust trading platforms to extensive altcoin listings, discover the best exchanges offering diverse trading options and liquidity for enthusiasts and investors looking to delve into the world of alternative cryptocurrencies.",
category: "Cryptocurrency",
date: "2024-01-23",
},
{
id: 20,
img: "/AllAssets/post20.png",
file: "post20",
title: "5 Reasons Why Ethereum Is More Than Just a Cryptocurrency",
author: "Benjamin Lee",
description: "Ethereum transcends mere cryptocurrency status with its versatile blockchain platform. Discover five compelling reasons why Ethereum stands out, from smart contract capabilities to decentralized applications. Explore the multifaceted world of Ethereum and its transformative impact beyond traditional digital currencies.",
category: "Cryptocurrency",
date: "2024-01-23",
},
].map((post) => ({
    ...post,
    slug: titleToSlug(post.title),
    url: `/${titleToSlug(post.title)}`,
  }));