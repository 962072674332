const titleToSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "");
};

export const posts = [
  {
  id: 1,
  img: "/AllAssets/post1.png",
  file: "post1",
  title: "Top 3 Budgeting Methods for Financial Success",
  author: "Liora Avraham",
  description: "Discover the three most effective budgeting methods for achieving financial success. From zero-based budgeting to the envelope system, learn about each method's benefits and how to implement them to take control of your finances and reach your financial goals.",
  category: "Budgeting Tips",
  date: "2024-01-11",
},
{
  id: 2,
  img: "/AllAssets/post2.png",
  file: "post2",
  title: "5 Reasons Why Budgeting is Essential for Financial Stability",
  author: "Liora Avraham",
  description: "Learn why budgeting is crucial for achieving and maintaining financial stability. From tracking expenses to saving for emergencies, discover the importance of budgeting in helping you manage your money effectively and achieve long-term financial security.",
  category: "Budgeting Tips",
  date: "2024-01-23",
},
{
  id: 3,
  img: "/AllAssets/post3.png",
  file: "post3",
  title: "Top 5 Budgeting Mistakes to Avoid",
  author: "Liora Avraham",
  description: "Learn about the most common budgeting mistakes and how to avoid them. From underestimating expenses to neglecting to account for irregular expenses, discover the pitfalls to watch out for and how to overcome them to stay on track with your budgeting goals.",
  category: "Budgeting Tips",
  date: "2024-01-21",
},
{
  id: 4,
  img: "/AllAssets/post4.png",
  file: "post4",
  title: " Top 10 Frugal Living Tips to Save Money",
  author: "Jonas Fischer",
  description: "Explore ten frugal living tips that can help you save money and live well on a budget. From cutting unnecessary expenses to embracing minimalism, discover practical strategies for reducing your spending and increasing your financial freedom.",
  category: "Budgeting Tips",
  date: "2024-01-21",
},
{
  id: 5,
  img: "/AllAssets/post5.png",
  file: "post5",
  title: "Ultimate Guide to Creating a Budget",
  author: "Jonas Fischer",
  description: "Get step-by-step instructions for creating a budget that works for you and helps you achieve financial freedom. From assessing your income and expenses to setting financial goals, learn how to develop a personalized budget plan that aligns with your priorities.",
  category: "Budgeting Tips",
  date: "2024-01-23",
},
{
  id: 6,
  img: "/AllAssets/post6.png",
  file: "post6",
  title: "4 Asset Allocation Strategies for Building Wealth",
  author: "Liora Avraham",
  description: "Explore the most effective asset allocation strategies used by investors to build wealth. From the classic 60/40 portfolio to the modern risk-parity approach, discover the best strategies for balancing risk and return and achieving long-term financial goals through diversified investments.",
  category: "Asset Allocation",
  date: "2024-03-03",
},
{
  id: 7,
  img: "/AllAssets/post7.png",
  file: "post7",
  title: "Top 8 Asset Classes to Consider for Your Investment Portfolio",
  author: "Jonas Fischer",
  description: "Dive into the world of asset allocation by exploring the top eight asset classes for building a diversified investment portfolio. From stocks and bonds to real estate and commodities, learn about each asset class's characteristics and how to allocate your investments for optimal returns.",
  category: "Asset Allocation",
  date: "2024-01-27",
},
{
  id: 8,
  img: "/AllAssets/post8.png",
  file: "post8",
  title: "5 Reasons Why Asset Allocation is Critical for Investment Success",
  author: "Nisha Gupta",
  description: "Understand the importance of asset allocation in achieving investment success. From managing risk and maximizing returns to achieving diversification and adapting to market conditions, discover why asset allocation plays a crucial role in building and preserving wealth over the long term.",
  category: "Asset Allocation",
  date: "2024-01-27",
},
{
  id: 9,
  img: "/AllAssets/post9.png",
  file: "post9",
  title: "5 Key Principles of Asset Allocation for Long-Term Investors",
  author: "Liora Avraham",
  description: "Understand the fundamental principles of asset allocation that guide successful long-term investors. From setting investment objectives to assessing risk tolerance and rebalancing your portfolio, learn how to apply these principles to build a resilient investment strategy that withstands market fluctuations and achieves your financial goals.",
  category: "Asset Allocation",
  date: "2024-01-23",
},
{
  id: 10,
  img: "/AllAssets/post10.png",
  file: "post10",
  title: "5 Asset Allocation Models Used by Financial Advisors",
  author: "Aaradhya Singh",
  description: "Explore the asset allocation models commonly used by financial advisors to help clients achieve their investment goals. From the strategic asset allocation model to the core-satellite approach, learn about the different models and how they can be tailored to meet your unique financial needs.",
  category: "Asset Allocation",
  date: "2024-01-23",
},
].map((post) => ({
  ...post,
  slug: titleToSlug(post.title),
  url: `/${titleToSlug(post.title)}`,
}));