export const BlogName = "ASSETALCHEMY-ID";

export const FeaturedText = "Discover small steps for big changes. Explore insights, inspiration, and actionable tips for a fulfilling life."

export const projectId = 'blog-7';
export const api = '645e5b2f737c77d97fdc35c962a72f5a';
export const SocialBar ='//pl23245906.highcpmgate.com/58/ad/a9/58ada933e423186e0c03616b4c4eb0b7.js'
export const PopUnder ='//pl23245632.highcpmgate.com/bf/b1/f8/bfb1f8160bf7326e58188638d2a8106c.js'
export const DirectLink = 'https://www.highcpmgate.com/mpxadffjt?key=d491edb7fac0f2327327477038144657'
export const NativeBanner = "//pl23245762.highcpmgate.com/c296e64c41ac15b869a8e02da35676af/invoke.js"
export const NativeBannerId ="container-c296e64c41ac15b869a8e02da35676af"
export const Bannar300x250 =   'c092e991d9755874b3e904432731851a'
export const Bannar160x600 ='f4ad87dd9f079d527a97ec49ab9546ef'
export const Bannar728x90 =  'eaabd1f303979174547bfba7a930b4c9'
export const Bannar468x60 =  '9820323539d21ab8749f143ab476dfba'
export const Bannar320x50  =  '3cdebc6103c8adc00d22eb4929a0d288'
export const Bannar160x300  = '7fe00391cfe20cab7eccab989bd0b89f'