
export const BlogName = "Moneyning";

export const FeaturedText = "Discover small steps for big changes. Explore insights, inspiration, and actionable tips for a fulfilling life."

export const projectId = 'blog-2';
export const api = '925ad13e726c59a372c7b7230f37b5c7';
export const SocialBar ='//pl23196955.highcpmgate.com/6e/6a/ae/6e6aae9f37529d67346c9dfaab5b2fad.js'
export const PopUnder ='//pl23196995.highcpmgate.com/0d/f9/45/0df945208872177f948024ff89ecaff9.js'
export const DirectLink = 'https://www.highcpmgate.com/gqfanhk6hj?key=b072c5661049e897e34e8f29ed57eaa3'
export const NativeBanner = "//pl23201862.highcpmgate.com/58909377db9f09a16831c8b2519aafcd/invoke.js"
export const NativeBannerId ="container-58909377db9f09a16831c8b2519aafcd"
export const Bannar300x250 = '2ecd96bd83e4fd26e955918f71627cd8'
export const Bannar160x600 ='942b5ed3b3e1f43b372539adf6476d18'
export const Bannar728x90 =  '0b8433afaee2c962acea1a44b1bb2ea3'
export const Bannar468x60 =  '0e90ebcad7529d1df25bf4b4262efcf4'
export const Bannar320x50  ='4d020fe6950b27f11a8acafc13a58a64'
export const Bannar160x300  =  '9b2447a1b9f72ce13e4a70b1b1e7bde8'