export const BlogName = "BELLINFOS-ID";

export const FeaturedText = "Discover small steps for big changes. Explore insights, inspiration, and actionable tips for a fulfilling life."

export const projectId = 'blog-9';
export const api = '7c88fa055be58509dd28c6b85c91869e';
export const SocialBar ='//pl23245964.highcpmgate.com/21/f4/a8/21f4a8200ec719946851b71ef718c94b.js'
export const PopUnder ='//pl23245640.highcpmgate.com/11/e1/bd/11e1bd32e8f1ca778cf29861f37504d9.js'
export const DirectLink = 'https://www.highcpmgate.com/id3tabhvr?key=bac54b1af19230eebf19546b0047af1d'
export const NativeBanner ="//pl23245790.highcpmgate.com/c244f60d8a5019a7ecb36f88f5c43800/invoke.js"
export const NativeBannerId ="container-c244f60d8a5019a7ecb36f88f5c43800"
export const Bannar300x250 =  'da4ffd73dba5443eb509f859b6b71b76'
export const Bannar160x600 ='df970b2702292b78a3ca9d14d8dae620'
export const Bannar728x90 = '295011c542902f0b28e48f42a5cfe542'
export const Bannar468x60 = 'fa777445cec2bde879d8c5daca396f20'
export const Bannar320x50  =  'ce591388b8face8023fe3681e257615c'
export const Bannar160x300  = 'cd5441c906c9de78a3e5514fd1cfc06a'