const titleToSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "");
};

export const posts = [
  {
    id: 1,
    img: "/AllAssets/post1.png",
    file: "post1",
    title: "Navigating Real Estate Freelancing: A Guide for Independent Agents",
    author: "Aaradhya Singh",
    description:
      "Dive into the dynamic world of real estate freelancing with our comprehensive guide. Discover essential strategies, tools, and insights to navigate the freelance landscape successfully. Whether you're a seasoned agent or just starting, unlock the secrets to thriving as an independent real estate professional.",
    category: "Real Estate",
    date: "2024-01-23",
  },
  {
    id: 2,
    img: "/AllAssets/post2.png",
    file: "post2",
    title:
      "Freelance Real Estate Writing: Crafting Compelling Property Descriptions",
    author: "Aaradhya Singh",
    description:
      "Learn the secrets to crafting captivating property descriptions in the world of freelance real estate writing. Dive into the nuances of language, imagery, and persuasion to entice potential buyers and elevate your real estate copywriting game.",
    category: "Real Estate",
    date: "2024-01-23",
  },
  {
    id: 3,
    img: "/AllAssets/post3.png",
    file: "post3",
    title: "Finding Success as a Freelance Real Estate Photographer",
    author: "Christopher Martinez",
    description:
      "Unlock the secrets to thriving as a freelance real estate photographer with our guide. Discover essential tips and strategies to elevate your skills, attract clients, and build a successful career in capturing captivating property images. Transform your passion for photography into a lucrative freelance venture in the dynamic real estate industry.",
    category: "Real Estate",
    date: "2024-01-23",
  },
  {
    id: 4,
    img: "/AllAssets/post4.png",
    file: "post4",
    title:
      "The Freelancer's Guide to Real Estate Transactions: Tips for Success",
    author: "Mia Weber",
    description:
      "Unlock the secrets to success in real estate transactions with our comprehensive guide for freelancers. Discover essential tips and strategies to navigate the intricacies of real estate deals, from negotiations to closings. Elevate your freelance career by mastering the art of facilitating seamless and successful real estate transactions.",
    category: "Real Estate",
    date: "2024-01-23",
  },
  {
    id: 5,
    img: "/AllAssets/post5.png",
    file: "post5",
    title:
      "Legal Considerations for Freelance Real Estate Consultants: What You Need to Know",
    author: "Mia Weber",
    description:
      "Dive into the legal essentials for freelance real estate consultants. Explore crucial considerations, from contracts to liability, ensuring compliance and protection in your consultancy. Arm yourself with vital knowledge to navigate legal landscapes confidently and safeguard your freelance consultancy practice.",
    category: "Real Estate",
    date: "2024-01-23",
  },
  {
    id: 6,
    img: "/AllAssets/post6.png",
    file: "post6",
    title: "Top 10 Cryptocurrencies to Watch in 2024",
    author: "Christopher Martinez",
    description:
      "Discover the top 10 cryptocurrencies poised to make waves in 2024. From established giants like Bitcoin to emerging contenders, explore the factors driving their potential growth and influence. Stay ahead of the curve with insights into the evolving landscape of digital assets and investment opportunities.",
    category: "Cryptocurrency",
    date: "2024-01-23",
  },
  {
    id: 7,
    img: "/AllAssets/post7.png",
    file: "post7",
    title: "Best 20 Resources for Learning About Cryptocurrency",
    author: "Christopher Martinez",
    description:
      "Discover the top 20 educational resources for delving into the world of cryptocurrency. From comprehensive guides and tutorials to online courses and forums, these curated resources provide invaluable insights and knowledge for beginners and experienced enthusiasts alike, empowering you to navigate the complex crypto landscape with confidence.",
    category: "Cryptocurrency",
    date: "2024-01-23",
  },
  {
    id: 8,
    img: "/AllAssets/post8.png",
    file: "post8",
    title: "5 Reasons Why Bitcoin Remains the King of Cryptocurrencies",
    author: "Christopher Martinez",
    description:
      "Discover why Bitcoin continues to reign supreme in the world of cryptocurrencies. From its first-mover advantage and widespread adoption to its scarcity and secure network, explore five compelling reasons why Bitcoin maintains its position as the undisputed king of digital assets.",
    category: "Cryptocurrency",
    date: "2024-01-23",
  },
  {
    id: 9,
    img: "/AllAssets/post9.png",
    file: "post9",
    title: "Best 10 Cryptocurrency Exchanges for Trading Altcoins",
    author: "Benjamin Lee",
    description:
      "Explore the top 10 cryptocurrency exchanges renowned for trading altcoins. From robust trading platforms to extensive altcoin listings, discover the best exchanges offering diverse trading options and liquidity for enthusiasts and investors looking to delve into the world of alternative cryptocurrencies.",
    category: "Cryptocurrency",
    date: "2024-01-23",
  },
  {
    id: 10,
    img: "/AllAssets/post10.png",
    file: "post10",
    title: "5 Reasons Why Ethereum Is More Than Just a Cryptocurrency",
    author: "Benjamin Lee",
    description:
      "Ethereum transcends mere cryptocurrency status with its versatile blockchain platform. Discover five compelling reasons why Ethereum stands out, from smart contract capabilities to decentralized applications. Explore the multifaceted world of Ethereum and its transformative impact beyond traditional digital currencies.",
    category: "Cryptocurrency",
    date: "2024-01-23",
  },
].map((post) => ({
  ...post,
  slug: titleToSlug(post.title),
  url: `/${titleToSlug(post.title)}`,
}));
