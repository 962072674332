const titleToSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "");
};

export const posts = [
  {
    id: 1,
    img: "/AllAssets/post1.png",
    file: "post1",
    title: "Mastering the Art of Personal Finance: A Beginner's Guide",
    author: "Christopher Martinez",
    description:
      "Learn the basics of personal finance, including budgeting, saving, and investing, to take control of your financial future.",
    category: "finance",
    date: "2024-01-11",
  },
  {
    id: 2,
    img: "/AllAssets/post2.png",
    file: "post2",
    title: "10 Essential Tips for Saving Money and Building Wealth",
    author: "Christopher Martinez",
    description:
      "Discover practical tips and strategies to save money, manage expenses, and build wealth for a secure financial future.",
    category: "Saving",
    date: "2024-01-23",
  },
  {
    id: 3,
    img: "/AllAssets/post3.png",
    file: "post3",
    title: "Understanding Investment Strategies: A Comprehensive Guide",
    author: "Christopher Martinez",
    description:
      "Explore different investment strategies, including stocks, bonds, mutual funds, and real estate, to make informed investment decisions.",
    category: "finance",
    date: "2024-01-21",
  },
  {
    id: 4,
    img: "/AllAssets/post4.png",
    file: "post4",
    title: "The Role of Cryptocurrency in Modern Finance",
    author: "Jonas Fischer",
    description:
      "Learn about the history and evolution of cryptocurrency and its impact on the modern financial landscape.",
    category: "finance",
    date: "2024-01-21",
  },
  {
    id: 5,
    img: "/AllAssets/post5.png",
    file: "post5",
    title: "How to Choose the Right Credit Card for Your Financial Needs",
    author: "Jonas Fischer",
    description:
      "Discover the key factors to consider when choosing a credit card that suits your financial needs and lifestyle.",
    category: "Credit Card",
    date: "2024-01-23",
  },
  {
    id: 6,
    img: "/AllAssets/post6.png",
    file: "post6",
    title: "The Future of Banking: Exploring Digital Payment Trends",
    author: "Christopher Martinez",
    description:
      "Explore the latest trends in digital payments, including mobile wallets, contactless payments, and blockchain technology.",
    category: "finance",
    date: "2024-03-03",
  },
  {
    id: 7,
    img: "/AllAssets/post7.png",
    file: "post7",
    title: "Navigating the Stock Market: A Beginner's Handbook",
    author: "Jonas Fischer",
    description:
      "Learn the basics of stock market investing, including how to research stocks, analyze market trends, and build a diversified portfolio.",
    category: "finance",
    date: "2024-01-27",
  },
  {
    id: 8,
    img: "/AllAssets/post8.png",
    file: "post8",
    title: "The Impact of Inflation on Your Savings and Investments",
    author: "Nisha Gupta",
    description:
      "Understand how inflation affects the purchasing power of your money and learn strategies to protect your savings and investments.",
    category: "finance",
    date: "2024-01-27",
  },
  {
    id: 9,
    img: "/AllAssets/post9.png",
    file: "post9",
    title: "Estate Planning 101: Securing Your Family's Financial Future",
    author: "Christopher Martinez",
    description:
      "Discover the importance of estate planning and learn how to protect your assets and secure your family's financial future.",
    category: "finance",
    date: "2024-01-23",
  },
  {
    id: 10,
    img: "/AllAssets/post10.png",
    file: "post10",
    title: "The Psychology of Spending: Understanding Your Financial Habits",
    author: "Aaradhya Singh",
    description:
      "Explore the psychological factors that influence spending behavior and learn how to develop healthy financial habits for long-term financial success.",
    category: "finance",
    date: "2024-01-23",
  },
].map((post) => ({
  ...post,
  slug: titleToSlug(post.title),
  url: `/${titleToSlug(post.title)}`,
}));
