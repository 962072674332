export const BlogName = "AFINANCESPOT-ID";

export const FeaturedText = "Discover small steps for big changes. Explore insights, inspiration, and actionable tips for a fulfilling life."

export const projectId = 'blog-6';
export const api = 'e532b2aabcf8969191cd4a4c3fee31b7';
export const SocialBar ='//pl23245901.highcpmgate.com/4b/ca/c2/4bcac2098b386ebd8dd63be9c9873bbb.js'
export const PopUnder ='//pl23245627.highcpmgate.com/b6/69/93/b66993f6336047756ccbecc33718e73e.js'
export const DirectLink = 'https://www.highcpmgate.com/zfpstvbmi?key=e6863ce0b8aa5aec85bb9472ece05a71'
export const NativeBanner ="//pl23245761.highcpmgate.com/cb7823db3d7140c30a3052023155c0c7/invoke.js"
export const NativeBannerId ="container-cb7823db3d7140c30a3052023155c0c7"
export const Bannar300x250 =  '19ea304a0b8cf7a5aa97aac4d5f1239d'
export const Bannar160x600 = '8178f3e27028e88e0ffba512083b5887'
export const Bannar728x90 =  '70f87a89f1aa83b47a3a851a445c4187'
export const Bannar468x60 =  '91070ac8a5ed8407aed2a0fe8e27f1a9'
export const Bannar320x50  =  'd34eeabe06b6f38c690a31c87820ef5d'
export const Bannar160x300  = '2f164e6fcd3fda1d5dbccd4ba3eae813'