const titleToSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "");
};

export const posts = [
  {
  id: 1,
  img: "/AllAssets/post1.png",
  file: "post1",
  title: "Top 10 Strategies for Paying Off Debt Faster",
  author: "Nisha Gupta",
  description: "Discover the most effective methods and techniques for accelerating your debt repayment journey. From snowball and avalanche methods to budgeting hacks and debt consolidation options, learn how to become debt-free sooner and achieve financial freedom.",
  category: "Debt",
  date: "2024-01-11",
},
{
  id: 2,
  img: "/AllAssets/post2.png",
  file: "post2",
  title: "5 Reasons Why Debt Consolidation Could Benefit You",
  author: "Talia Goldberg",
  description: "Explore the advantages of consolidating your debts into a single, manageable payment. From simplifying your finances to potentially lowering your interest rates, discover why debt consolidation might be the key to regaining control of your financial situation.",
  category: "Debt",
  date: "2024-01-23",
},
{
  id: 3,
  img: "/AllAssets/post3.png",
  file: "post3",
  title: "The Ultimate Guide to Managing Credit Card Debt",
  author: "Talia Goldberg",
  description: "Dive into expert tips and strategies for effectively managing and reducing credit card debt. From creating a repayment plan to avoiding common pitfalls, this comprehensive guide will help you take control of your credit card balances and improve your financial health.",
  category: "Debt",
  date: "2024-01-21",
},
{
  id: 4,
  img: "/AllAssets/post4.png",
  file: "post4",
  title: "5 Common Debt Mistakes to Avoid at All Costs",
  author: "Jonas Fischer",
  description: "Learn from the mistakes of others and avoid common pitfalls that can derail your debt repayment progress. From overspending and ignoring interest rates to neglecting emergency savings, uncover the most detrimental debt mistakes and how to steer clear of them.",
  category: "Debt",
  date: "2024-01-21",
},
{
  id: 5,
  img: "/AllAssets/post5.png",
  file: "post5",
  title: "Top 5 Debt Relief Options Explained",
  author: "Jonas Fischer",
  description: "Gain insight into the various debt relief options available to individuals struggling with debt. From debt settlement and debt management plans to bankruptcy and credit counseling, learn how each option works and determine which one may be right for your financial situation.",
  category: "Debt",
  date: "2024-01-23",
},
{
  id: 6,
  img: "/AllAssets/post6.png",
  file: "post6",
  title: "5 Reasons Why Content Marketing is Essential for Your Business",
  author: "Nisha Gupta",
  description: " Learn why content marketing is a cornerstone strategy for businesses looking to attract, engage, and retain customers. From building brand awareness and establishing thought leadership to driving website traffic and generating leads, discover the undeniable benefits of content marketing for your business.",
  category: "Marketing",
  date: "2024-03-03",
},
{
  id: 7,
  img: "/AllAssets/post7.png",
  file: "post7",
  title: "5 Effective Email Marketing Strategies to Boost Conversions",
  author: "Jonas Fischer",
  description: "Uncover proven strategies for maximizing the effectiveness of your email marketing campaigns. From personalization and segmentation to automation and A/B testing, discover the tactics that will help you increase open rates, click-through rates, and conversions for your email marketing efforts.",
  category: "Marketing",
  date: "2024-01-27",
},
{
  id: 8,
  img: "/AllAssets/post8.png",
  file: "post8",
  title: "5 Key Metrics to Measure the Success of Your Marketing Campaigns",
  author: "Nisha Gupta",
  description: "Learn how to effectively measure and analyze the performance of your marketing campaigns. From tracking website traffic and conversion rates to monitoring social media engagement and ROI, discover the essential metrics that will help you evaluate the success of your marketing efforts and optimize for better results.",
  category: "Marketing",
  date: "2024-01-27",
},
{
  id: 9,
  img: "/AllAssets/post9.png",
  file: "post9",
  title: "5 Creative Marketing Ideas to Boost Your Brand's Visibility",
  author: "Nisha Gupta",
  description: "Spark creativity and innovation in your marketing efforts with these 5 actionable ideas. From hosting virtual events and launching viral challenges to partnering with local influencers and leveraging user-generated content, discover unique and effective ways to increase your brand's visibility and reach.",
  category: "Marketing",
  date: "2024-01-23",
},
{
  id: 10,
  img: "/AllAssets/post10.png",
  file: "post10",
  title: "Top 5 Tools Every Marketer Should Use",
  author: "Aaradhya Singh",
  description: "From analytics platforms and social media management tools to email marketing software and SEO plugins, explore the top tools that will streamline your marketing efforts and drive better results.",
  category: "Marketing",
  date: "2024-01-23",
},
 
].map((post) => ({
  ...post,
  slug: titleToSlug(post.title),
  url: `/${titleToSlug(post.title)}`,
}));