export const BlogName = "ACENTATATIME";
export const FeaturedText = "Discover small steps for big changes. Explore insights, inspiration, and actionable tips for a fulfilling life."

export const projectId = 'blog-1';
export const api = 'c69b36d7db357ed7cbb6f4343c45e921';
export const SocialBar ='//pl23189617.highcpmgate.com/b3/b0/2b/b3b02be924496c6f092037c9881554bc.js'
export const PopUnder ='//pl23189692.highcpmgate.com/6b/49/41/6b494185801faf23bc1da3e3b3fbf442.js'
export const DirectLink = 'https://www.highcpmgate.com/uny1b564?key=8f99606b74a3faffeba3ec9e5169845f'
export const NativeBanner = "//pl23189777.highcpmgate.com/93d7454bfd6fb0d286a0acd5158da893/invoke.js"
export const NativeBannerId ="container-93d7454bfd6fb0d286a0acd5158da893"
export const Bannar300x250 = '93e33946fc005a2b400d3c5f71edb4bc'
export const Bannar160x600 ='5f4aba5f59dab6ba2db7d5e02e2ab2b8'
export const Bannar728x90 =  'a39f06c45023ee5ef54e241980bfb44f' 
export const Bannar468x60 = '7a079003ec95b82daa1689818f952566'
export const Bannar320x50  = 'b83aaef8055dc8e211564bfb7d56b311'
export const Bannar160x300  = '23331c69eaded348a0ca37edb0a13c9a'